<template>
  <div
    v-if="template"
    :class="$style.createProcessFromTemplate"
  >
    <div :class="$style.name">
      <b-field
        label="Process name"
        :type="processValidationError && 'is-danger'"
        :message="processValidationError"
      >
        <b-input
          ref="name"
          v-model="processName"
          :disabled="loading"
          placeholder="Process name"
          @input="processValidationError = null"
          @keydown.native.enter="save"
        />
      </b-field>
    </div>

    <b-field
      v-if="template.publicFormExists"
      label="Create rich web form"
    >
      <b-switch
        v-model="generateForm"
      />
      <span v-if="generateForm">
        You will get a free
        <a
          href="https://plumsail.com/forms/store/public-forms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Plumsail Forms
        </a> "Scooter" subscription if you don't have one
      </span>
    </b-field>

    <div :class="$style.controls">
      <b-button
        type="is-light"
        :disabled="loading"
        @click="cancel"
      >
        Cancel
      </b-button>

      <b-button
        type="is-primary"
        :loading="loading"
        @click="save"
      >
        Next
      </b-button>
    </div>

    <PDFViewer
      :link="templateLink"
      :type="templateType"
    />
  </div>
</template>

<script>
import { mapWritableState, mapActions } from 'pinia';
import { locale, currentTimezone as timezone } from '@/utils/index.js';
import {
  useProcessesStore,
  useTemplatesStore,
  useHelpStore,
  useErrorsStore,
} from '@/stores/index.js';

export default {
  name: 'ProcessCreateFromTemplate',
  components: {
    PDFViewer: () => import('./components/pdf-viewer.vue'),
  },
  data() {
    return {
      processValidationError: null,
      processName: null,
      loading: false,
      generateForm: false,
    };
  },
  computed: {
    ...mapWritableState(useTemplatesStore, ['template']),
    templateLink() {
      return this.template?.previewUrl;
    },
    templateType() {
      return this.template?.type;
    },
  },
  watch: {
    template(value) {
      return value && this.$nextTick(() => this.$refs.name.focus());
    },
    processValidationError(value) {
      return value && this.$nextTick(() => this.$refs.name.focus());
    },
  },
  async created() {
    await this.readTemplate({ slug: this.$route.params.slug });

    this.setHelpOpen(true);
  },
  mounted() {
    this.$refs.name?.focus();
  },
  beforeDestroy() {
    this.setHelpOpen(false);
  },
  destroyed() {
    this.template = null;
  },
  methods: {
    ...mapActions(useHelpStore, { setHelpOpen: 'setOpen' }),
    ...mapActions(useTemplatesStore, ['readTemplate']),
    ...mapActions(useProcessesStore, ['readProcesses', 'createProcessWithTemplate']),
    ...mapActions(useErrorsStore, ['setError']),

    async save() {
      this.processValidationError = null;

      this.$nextTick(async () => {
        // For better user experience: if processValidationError exist focus name input
        if (!this.processName) {
          this.processValidationError = 'Please, fill the process name';
          return;
        }

        if (this.processName.includes('<') || this.processName.includes('>')) {
          this.processValidationError = 'Name cannot contain: < >';
          return;
        }

        this.loading = true;

        // TODO: speak with @Roman how to solve this annoying logic
        // createProcess should throw error if process with current name already exist
        // but we create template before, and it's possible have unused templates
        const { data } = await this.readProcesses();

        if (data.some(({ processName }) => processName === this.processName)) {
          this.processValidationError = 'Please, provide the unique process name';
          this.loading = false;
          return;
        }

        try {
          const { data: processData, error } = await this.createProcessWithTemplate({
            templateId: this.template.id,
            processName: this.processName,
            generateForm: this.generateForm,
            locale,
            timezone,
          });

          if (error) {
            throw new Error(error.message);
          }

          this.loading = false;
          this.$router.push({ name: 'DocumentsProcessEdit', params: { processId: processData.id } });
        } catch (error) {
          this.loading = false;
          this.setError(error);
        }
      });
    },
    cancel() {
      return this.$router.push({ name: 'DocumentsTemplatesList' });
    },
  },
};
</script>

<style lang="scss" module>
.createProcessFromTemplate {
  position: relative;
  width: 50%;

  .name {
    margin-bottom: 15px;
  }

  .controls {
    display: flex;
    gap: 8px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    width: initial;
  }
}
</style>
